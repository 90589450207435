@import url('https://fonts.googleapis.com/css?family=Lato:300,400,500,600,700');

$app-color = #3b65de;
$app-bg = #fff;

*
    box-sizing border-box

html, body
    width 100%
    height 100%
    margin 0
    padding 0
    font-family "Lato", sans-serif


body
    display block
    background linear-gradient(darken($app-bg, 2%), darken($app-bg, 7%))


canvas
    width 100%
    height 100%
    display block

section
    position: absolute
    left 0
    right 0
    top 0
    bottom: 0
    overflow: auto
    display: flex;
    flex-direction: column
    padding: 50px;

    header
        margin: -50px;
        padding: 50px;

        .name
            text-transform: uppercase
            letter-spacing: 0.3ch
            font-size 20px
            font-weight: 300

            & > div
                display: inline
            
            .first
                color $app-color
            
            .sur
                color desaturate($app-color, 100%);
        background: rgba(0, 0, 0, 0.02);
        box-shadow: 0 0 50px rgba(150, 150, 150, 0.1);

    .promo
        flex 1
        display flex
        justify-content: center;
        flex-direction: column;

        .lines
            font-size: 64px;
            font-weight: 600;
            color #222
            color desaturate($app-color, 20%);

            span
                color lighten(#222, 5%)
                color darken(desaturate($app-color, 90%), 40%);
        
        .expertise
            padding-top: 20px;
            display: flex
            flex-wrap: wrap
            img
                width: 48px
                height: 48px
                margin-right 20px
                filter: grayscale(100%);
                transition: filter 0.1s;

                &:hover
                    filter: grayscale(0%);